<template>
  <div>
    <span class="title-ticket">取り消し可能TICKET</span>
    <div>
      <div v-for="item in tickets" :key="item.id" class="listGroup-ticket d-flex justify-content-between">
        <span>
          {{ item.name }}<br>{{ item.amountBreakdown | comma }}円{{ specialUnit }}{{ item.unitBreakdown }}枚
        </span>
        <span>
          {{ item.quantities + quantityUnit }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TicketList',
  data() {
    return {
      quantityUnit: '　口',
      specialUnit: '　ｘ　'
    }
  },
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.listGroup-ticket {
  padding-left: 50px;
  align-items: flex-end;
}

.title-ticket {
  padding-left: 20px;
}
</style>
